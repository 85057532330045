@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html {
  font-size: 100%;
}

body {
  margin: 0;
  min-height: 100vh;
  background-color: var(--light-grey-2);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif;
}

:root {
  /* colors */
  --black: #000000;
  --white: #ffffff;
  --red: #d93845;
  --orange: #ff5733;
  --opacity-orange: rgba(255, 87, 51, 0.05);
  --grey: #d2d2d2;
  --grey-2: #6c757d;
  --dark-grey: #212529;
  --light-grey: #eeeeee;
  --light-grey-2: #f8f9fa;
  --light-grey-3: #dee2e6;
  --primary-main: #0d6efd;
  --primary-dark: #0b5ed7;
  --primary-light: #0dcaf0;
  --primary-light-2: #4096ff;
  --light-blue: #3498db;
  --opacity-light-blue: rgba(52, 152, 219, 0.08);
  --light-green: #53c41a;
  --opacity-light-green: rgb(83, 196, 26, 0.08);
  --opacity-dark-grey: rgb(33, 37, 41, 0.08);
}

/* typographies */
.HeadlineH1DarkGreyBold {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.HeadlineH1WhiteBold {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--white);
}
.HeadlineH2DarkGreyBold {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.HeadlineH3DarkGreyBold {
  font-size: calc(1.6rem);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.HeadlineH3DarkGreyLight {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.HeadlineH4DarkGreyBold {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.HeadlineH4DarkGreyRegular {
  font-size: 1.25rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.HeadlineH5DarkGreyRegular {
  font-size: 1.5rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.HeadlineH6WhiteBold {
  font-size: 1rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--white);
}
.HeadlineH6DarkGreyBold {
  font-size: 1rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.HeadlineH6DarkGreyRegular {
  font-size: 1rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.Body1DarkGreyRegular {
  font-size: 1.1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4rem;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.Body1DarkGreyBold {
  font-size: 1rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.Body1WhiteRegular {
  font-size: 1rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}
.Body1WhiteSmall {
  font-size: 0.875rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3rem;
  letter-spacing: normal;
  color: var(--white);
}
.Body1DarkGreySmall {
  font-size: 0.875rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.Body1GreySmall {
  font-size: 0.82rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--grey-2);
}
.Body1DarkGrey12Bold {
  font-size: 0.75rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark-grey);
}
.Body1DarkGrey12Regular {
  font-size: 0.75rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark-grey);
}

/* global */

/* Antd buttons */
.ant-btn-primary {
  background-color: var(--primary-main);
  font-weight: 500;
  box-shadow: none;
}
.ant-btn-default {
  border-color: var(--primary-main);
  color: var(--primary-main);
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: var(--primary-light-2);
}

/* Antd input */
.ant-input-affix-wrapper .ant-input-prefix {
  margin-inline-end: 0;
}

/* Antd menu */
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu:hover::after {
  border: none;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-active::after {
  border: none;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-open::after {
  border: none;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
  border: none;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected {
  border: none;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after {
  border: none;
}

/* Antd DatePicker */
.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
  color: var(--black);
}

.ant-picker:hover .ant-picker-suffix:not(:last-child) {
  opacity: 1;
}

@media (max-width: 650px) {
  .ant-picker-panel:last-child .ant-picker-date-panel {
    display: none !important;
  }

  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-next-btn,
  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-super-next-btn {
    visibility: initial !important;
  }

  .ant-picker-panel.ant-picker-range-wrapper {
    min-width: 288px !important;
  }

  .ant-picker-presets {
    display: none !important;
  }

  .ant-picker-panels,
  .ant-picker-datetime-panel {
    flex-direction: column !important;
  }
}

.ant-picker-panel-container {
  .ant-picker-presets {
    min-height: 25rem !important;
  }
  @media (max-width: 650px) {
    overflow: scroll !important;
    height: 330px;
    .ant-picker-panel-layout {
      flex-direction: column !important;

      .ant-picker-presets {
        max-width: 100% !important;
        min-height: 10rem !important;
      }

      .ant-picker-panels,
      .ant-picker-datetime-panel {
        flex-direction: column !important;
      }
    }
  }
}
